<template>
    <div class="healthKnow zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>院前急救</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link to="/familyFirstAid/familyFirstAid">院前急救</router-link><i class="el-icon-arrow-right"></i><a>院前急救详情</a></p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content clearfix">
                <!-- <div class="content-left">
                    <el-scrollbar style="height: 450px;">
                        <div class="left-nav sidenav">
                            <el-menu class="el-menu-vertical-demo" :default-active="defaultActive + '2'" unique-opened>
                                <template>
                                    <el-submenu v-for="(sideNav, index) in  categoryData " :key="index" :index="index + ''">
                                        <template slot="title">
                                            <span data-size="16">{{ sideNav.categoryName }}</span>
                                        </template>
                                        <div class="subtitle">
                                            <el-menu-item v-for="(subItem, i) in sideNav.familyFirstAids" :key="i"
                                                :index="subItem.id + '2'" @click="Detail(subItem.id)">
                                                <template slot="title">
                                                    <span data-size="12"> {{ subItem.name }}</span>
                                                </template>
                                            </el-menu-item>
                                        </div>
                                    </el-submenu>
                                </template>
                            </el-menu>
                        </div>
                    </el-scrollbar>
                </div> -->
                <div class="ny_container">
                    <h2 class="ny-right-tit" v-if="detailInfo" data-size="24">{{ detailInfo.healthName }}</h2>
                    <ul class="ny-list" v-if="detailInfo">
                        <li class="ny-item" v-for="( info, index ) in  detailInfo.infos " :key="index">
                            <h3 v-if="info.title" data-size="18"><span></span>{{ info.title }}</h3>
                            <div class="ny-mes">
                                <p data-size="16" v-if="info.info" v-html="math(info.info.toString().replace(/\n/g, '</p> <p>'))"></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import { FamilyFirstAid } from "../../components/familyFirstAid/familyFirstAid"
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
// import rtBar from "../../components/right";
export default {
    name: "",
    components: {
        hdCom,
        ftCom,
        // rtBar
    },
    data() {
        var familyFirstAid = new FamilyFirstAid(this.TokenClient, this.Services.Disease)
        return {
            categoryData: {},
            detailInfo: {}, //初始化详情
            familyFirstAidDomain: familyFirstAid,
            defaultActive: 0,
            analysisRank: [],
            fontSize: ""
        }
    },
    methods: {
        math(info) {
            return replaceContent(info);
        },
        getFontSize(data) {
            this.fontSize = data
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;
            let scrollObj = document.querySelector(".content-left");
            //scrollObj 这个是下图右边答题卡的div
            if (scrollY > 300) {
                scrollObj.style.position = "fixed";
                scrollObj.style.top = "0";
            } else {
                scrollObj.style.position = "absolute";
                scrollObj.style.top = "auto";
            }
        },
        Init: function () {
            var _this = this;
            _this.rloading = this.openLoading()
            _this.familyFirstAidDomain.FamilyFirstAid(function (data) {
                _this.categoryData = (data.data);
                if (_this.categoryData.length > 0) {
                    _this.defaultActive = _this.categoryData[0].familyFirstAids[0].id;

                }
                _this.rloading.close();
                _this.Detail(_this.defaultActive);
            },
                function (error) {
                    console.log('健康常识请求异常!请刷新重试。', error)
                })
        },
        //详情获取
        Detail: function () {
            let params = `${this.$route.query.id}`
            var _this = this;
            _this.familyFirstAidDomain.Detail(params, function (data) {
                _this.detailInfo = (data.data)

                _this.$bus.$emit('fontSize');
            },
                function (error) {
                    console.log('健康常识详情请求异常!请刷新重试。', error)
                })
        },
        replace: function (content) {
            return content.replace(/\n/g, '</p> <p>')
        },
        getPageInfo(name, type, hashKey) {
            if (hashKey == 'Search') {
                toSearchList(checkNameAndPath(type).typeName, name)
            } else {
                toDetailInfo(checkNameAndPath(type).path, hashKey, type)
            }
        },
    },
    mounted() {
        this.Init();
        window.getPageInfo = this.getPageInfo;
        window.addEventListener("scroll", this.handleScroll);
    },
    watch: {
        detailInfo: function () {
            var _this = this;

            _this.$nextTick(function () {
                $(document).ready(function () {
                    var that = $(".ny-mes").find("table");
                    //给所有table元素标签外层嵌套一个div标签
                    that.wrap('<div class="table-responsive"></div>');
                });

            });

        }
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>

<style scoped>
.banner {
    height: 116px;
    background: url("../../assets/img/jbk_banner_zy.jpg")center no-repeat;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 18px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.content-wrap {
    background: #fcfcfc;
}

.content-top {
    height: 64px;
    background: #FFFFFF;
    box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
}

.content {
    position: relative;
    width: 1440px;
    background: none;
    margin: 0 auto;
    padding: 30px 0;
    min-height: 500px;
}

.content-left {
    position: absolute;
    width: 350px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
    max-height: 450px;

}

.left-nav-wrap {

    width: 260px;
    max-height: 520px;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.left-nav {
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
}

.ny_container {
    /* float: right; */
    /* width: 1030px; */
    background: #FFFFFF;
    box-shadow: 0px 5px 5px rgba(224, 224, 224, .3);
    padding: 10px 0;
}

.ny-right-tit {
    text-align: center;
    margin: 10px 0;
    font-weight: normal;
    font-size: 24px;
}

.ny-right-info {
    padding: 0 30px;
    color: #454545;
    font-size: 15px;
    line-height: 30px;
}

.ny-list .ny-item h3 {
    font-size: 18px;
    padding: 15px 0;
}

.ny-list .ny-item h3 span {
    display: inline-block;
    width: 20px;
    height: 8px;
    margin-right: 20px;
    background: #df7d1f;
}

.ny-list .ny-item .ny-mes {
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ny-list .ny-item .ny-mes p {
    color: #787878;
    font-size: 16px;
    line-height: 32px;
    text-indent: 32px;
}

.ny-list .ny-item .ny-mes.all {
    max-height: initial;
}

.ny-item-child h4 {
    list-style: circle;
    font-weight: 700;
    color: #333333;
    margin: 10px 0;
}

.ny-list .ny-item h4 span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background: #1fb7a6;
}

.readAllBtn {
    position: relative;
    height: 78px;
    margin-top: -68px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), #ffffff);
    text-align: center;
}

.xi .readAllBtn .btn-text {
    position: absolute;
    bottom: 0;
    color: #1fb7a6;
    cursor: pointer;
}

/* **************** */
.sidenav /deep/ .el-submenu__title>span {
    font-size: 16px;
    font-weight: 700;
}

.sidenav /deep/ .el-submenu__title i {
    color: #ffffff;
}

.ny-mes .table-responsive {
    width: 100% !important;
    overflow: auto !important;
}

.healthKnow .bar-list .yysc {
    display: none !important;
}

.xi /deep/ .el-submenu__title {
    padding: 0 !important;
    background: #1fb7a6;
    color: #ffffff;
    margin-top: 7px;
}

.xi .subtitle /deep/ .el-submenu__title {
    padding-left: 30px !important;
    background: #fff;
    color: #333;
    margin-top: 0;
    height: 40px;
    line-height: 40px;
}

.xi .subtitle /deep/ .el-submenu__title:hover {
    background: rgba(31, 183, 166, 0.2);
}

.xi .subtitle /deep/ .el-submenu__title i {
    color: #000000;
}

.xi .el-menu-item.is-active,
.xi .el-menu-item:hover {
    color: #01C2AC;
    background: rgba(31, 183, 166, 0.2);
}

.zhong .el-menu-item.is-active,
.zhong .el-menu-item:hover {
    color: #b76c12;
    background: rgba(183, 108, 18, 0.2);
}

.el-image__inner {
    height: 150px;
    width: auto;
}

.healthKnow .el-radio.is-bordered {
    border-radius: 2px;
    width: 156px;
    text-align: center;
}

.healthKnow .el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 5px;
}

.healthKnow .el-radio {
    margin-right: 0;
}

.healthKnow .el-checkbox-group {
    display: inline;
}

.healthKnow .el-checkbox {
    width: 33.3%;
    margin: 10px 0;
}

/*.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #01c2ac;
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner{
      border-color: #01c2ac;
    }
    .el-checkbox__inner:hover{
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #01c2ac ;
    }*/
.healthKnow .el-checkbox__inner {
    width: 16px;
    height: 16px;
}

.healthKnow .el-checkbox__inner::after {
    height: 9px;
    left: 5px;
    width: 4px;
}

.healthKnow .el-badge__content.is-dot {
    height: 6px;
    width: 6px;
}

.healthKnow .el-badge__content.is-fixed.is-dot {
    right: 0px;
    top: 11px;
}

.healthKnow .el-submenu__title * {
    margin-left: 10px;
}

.el-menu {
    border-right: none;
}

.el-submenu__title i {
    color: #ffffff;
}

.el-menu-item,
.el-submenu__title {
    height: 48px;
    line-height: 48px;
}

.subtitle {
    border: 1px solid #f2f2f2;
}

.subtitle .el-submenu__title i {
    color: #333333;
}

.subtitle .el-submenu__title {
    padding-left: 30px !important;
    background: #ffffff;
    color: #333333;
    margin-top: 0;
    height: 36px;
    line-height: 36px;
}

.subtitle .el-submenu__title:hover {
    color: #1fb7a6;
}

.el-submenu .el-menu-item {
    padding-left: 50px !important;
    height: auto !important;
    line-height: 36px;
    white-space: pre-wrap;
}

.el-menu-item:focus,
.el-menu-item:hover {
    background-color: #ffffff;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.el-pagination {
    font-weight: 500;
}

.xi .el-radio.is-bordered.is-checked {
    border-color: #01C2AC;
}

.xi .el-radio__input.is-checked .el-radio__inner {
    border-color: #01C2AC;
    background: #01C2AC;
}

.xi .el-radio__input.is-checked+.el-radio__label {
    color: #01C2AC;
}

.zhong .el-radio.is-bordered.is-checked {
    border-color: #b76c12;
}

.zhong .el-radio__input.is-checked .el-radio__inner {
    border-color: #b76c12;
    background: #b76c12;
}

.zhong .el-radio__input.is-checked+.el-radio__label {
    color: #b76c12;
}

.el-scrollbar__wrap {
    overflow: auto;
}
.ny-list /deep/ .ny-item .ny-mes a {
    color: #1fb7a6 !important;
}
</style>
